/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    strong: "strong"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "ear-candling",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ear-candling",
    "aria-label": "ear candling permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ear candling"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-impaired-phone-hero.png",
    alt: "Leaf falling from the sky",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-you-should-not-use-ear-candles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-you-should-not-use-ear-candles",
    "aria-label": "why you should not use ear candles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why you should NOT use ear candles"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ear Candling is a practice that alternative medicine practitioners claim will remove unwanted ear wax, impurities and toxins from the ear. It is also alleged to contribute to a greater sense of well-being as well as enhanced physical and mental health. In the practice, a person lies down with one ear facing upwards. A candle is then positioned in the ear and set alight. The practice is also known as thermal auricular therapy and ear coning, because of the particular shape of the candles used."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "are-ear-wax-candles-good-for-you-how-do-they-work",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#are-ear-wax-candles-good-for-you-how-do-they-work",
    "aria-label": "are ear wax candles good for you how do they work permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Are ear wax candles good for you? How do they work?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Proponents of the practice make several different claims about the mechanism by which the practice achieves its aims. Some claim that the heat of the lighted candle creates a kind of suction that pulls wax and other impurities from the ear and into the candle. Others claim that the heat from the candle melts and softens impacted ear wax, which then ‘falls out’ over the course of the next few days. Ear candlers also claim that the ear passageway is connected to all the other passages in your head and, therefore, the practice serves to clean out all these other unspecified passages."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "What is an ear candle?")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to proponents of the practice, only specially-made cone-shaped candles are suitable for thermal-auricular therapy. Ear candles are created from a fabric structure that is coated in one of three different kinds of wax. The pointed end is placed in the ear while the wider end is set alight. Adherents also advise that the use of an ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/earwax",
    className: "c-md-a"
  }, "ear wax removal"), " candle must only be carried out by experienced practitioners, whose services can be secured for a nominal fee. These practitioners will often crack open the ear candle after a session to reveal a potentially disturbing build-up of debris and wax inside. This is held forth as evidence of various kinds of impurities and ear wax that has just been drawn from the ear canal."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "so-are-ear-wax-candles-good-for-you-what-medical-science-has-to-say",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#so-are-ear-wax-candles-good-for-you-what-medical-science-has-to-say",
    "aria-label": "so are ear wax candles good for you what medical science has to say permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "So, are ear wax candles good for you? What medical science has to say"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unfortunately, there is ", React.createElement(_components.strong, null, "no medical evidence to support the benefits of thermal-auricular therapy"), " alleged by practitioners."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Claim/Benefit number 1:"), " Ear Candles remove ear wax by creating suction."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This claim can be disproved with a basic understanding of how ear wax works. Earwax is sticky and adheres quite strongly to the inner sides of the ear. Consequently, an extremely strong form of suction would be required to remove it. The strength of this suction would easily be felt by the person undergoing the process, which is at odds with the sensations reported by those who have undergone ear coning. In addition to this, a study into the process found that not only did those ear wax removal candles tested failed to create a strong enough suction to realistically remove ear wax, but they actually failed to create any kind of suction at all."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Claim/benefit number 2:"), " An ear wax removal candle works by melting the wax and forcing it to fall out."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Common earwax does not have a particularly high melting point in comparison to something like metal or even plastic, but it does have one in comparison to body temperature. However, studies carried out found that temperatures created by the candle in the ear canal were below body temperature and therefore far too low to melt the wax. In addition, wax will reharden shortly after the heat source melting it has been removed, so it does not make sense that softened wax would fall out over a period of several days."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Claim Number 3:"), " The debris found when an ear candle is opened is evidence of earwax, impurities and toxins removed during the process."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Studies have shown that this debris builds up whether the candle is used on a person or simply set alight on its own. A study also analyzed the debris found in ear candles after use and discovered that it only contained materials already present in the candle itself."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Claim Number 4:"), " Thermal-auricular therapy serves to clear out the interconnected passageways in your skull and leave you with a clean head."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This description of interconnected passageways is simply at odds with our understanding of the structure of the head and ear channels. The ear drums are one of the barriers that seal the ear canal passageway off from the rest of the head’s internal structure."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unfortunately, there is no scientific evidence yet to support the claims of ear candlers. What is even more unfortunate, however, is that there is a great deal of evidence to indicate that there is a variety of inherent risks that go along with the practice. ", React.createElement(_components.strong, null, "The United States Food and Drug Administration(FDA) has even gone so far as to issue a warning advising against the practice."), " Side effects include burns, perforated eardrums and ear canal blockages. Blockages occur when even small amounts of wax from the candle drip down into the ear cavity. This can result in ", React.createElement(_components.a, {
    href: "/hearing-loss",
    className: "c-md-a"
  }, "short or even long term hearing loss"), " and will likely require surgery to resolve. Burns have been reported as a consequence of both the open flame in close proximity to the face and the hot wax coming into contact with skin and even sensitive parts of the inner ear. Eardrum perforation can be a particularly serious side effect. It can occur when the pointed end of the candle is placed too deeply into the ear and comes into contact with the eardrum itself."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "alternatives-to-ear-candling",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#alternatives-to-ear-candling",
    "aria-label": "alternatives to ear candling permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Alternatives to ear candling"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are a variety of medically approved alternatives to ear candling that can be carried out at home or by a doctor. An ear flush or ear irrigation is a routine procedure that can be carried out by a doctor or performed using a home kit. It involves ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/clean-ears/",
    className: "c-md-a"
  }, "‘flushing’ the ear gently"), " using a bulb syringe filled with simple warm water or a combination ofwater and a saline solution. Ear flushing should never be carried out if one is experiencing a perforated eardrum, a lowered immune system or an active ear infection. When in doubt, it is always best to seek medical advice first. Drops for softening earwax can also be purchased over the counter from pharmacies."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Currently, alternative practitioners are not required to undergo the same kinds of standardized training and testing that medical practitioners are subject to. Consequently, it is advisable to exercise caution and diligence before visiting any given provider of an alternative medicine, and, if in doubt, consult with a medically qualified doctor who can advise you on the potential benefits and risks of the practice."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
